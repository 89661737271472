<template>
	<div v-if="orgs && orgs.length > 0" class="font-14 dashboard-col">
		<span class="org-breadcrumb title mt-1">{{ $t('Dashboard.FilteredOrg') }}</span>
		<span v-for="org in orgs" :key="org" role="alert" class="alert alert-info org-breadcrumb mt-1">{{ org.replace(/org_(\d)_not_assigned_label/g, "N.A.") }}</span>
	</div>
</template>

<script>
export default {
	props: {
		orgs: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style lang="less" scoped>

span.org-breadcrumb {
	display: flex;
	margin-left: 5px;
	font-weight: 500;
	padding: 2px 6px 2px 5px;
	float: left;
	margin: 0 5px;
	white-space: nowrap;
	border-radius: 12px;
	&.title {
		background-color: transparent;
		padding-right: 0;
	}
}
</style>