const floatFormat = function (number) {
  return Number(number.toFixed(1)).toLocaleString();
};

const integerFormat = function (number) {
  return Number(number.toFixed(0)).toLocaleString();
};

const truncateStr = function (str, length) {
	if (!str) {
		return 'unknown';
	}

	if (str.length >= length) {
		return str.slice(0, str.length).substring(0, length - 1).trim() + '...';
	}
	return str;
};

export { floatFormat, integerFormat, truncateStr}