import colors from "@/components/charts/utils/colors";
import { truncateStr } from "@/components/utils";

const HBarChartsOptions = {
	scales: {
		xAxes: [
			{
				ticks: {
					min: 0,
					callback: function (value) {
						return value + "%";
					}
				},
				scaleLabel: {
					display: true
				}
			}
		],
		yAxes: [
			{
				ticks: {
					callback: function (value) {
						return value ? value : "N.A.";
					}
				},
				gridLines: {
					display: false
				}
			}
		]
	},
	responsive: true,
	maintainAspectRatio: false,
	animation: {
		animateRotate: true,
		animateScale: true
	},
	tooltips: {
		backgroundColor: colors.plain.white,
		titleFontColor: colors.plain.primary,
		bodyFontColor: colors.plain.primary,
		borderColor: colors.plain.grey,
		borderWidth: 0.5,
		cornerRadius: 10,
		xPadding: 15,
		yPadding: 10,
		callbacks: {
			label: function (tooltipItem, data) {
				let category = data.datasets[tooltipItem.datasetIndex].label;
				return category + " " + tooltipItem.value + "%";
			},
			title: function (tooltipItem) {
				let category = tooltipItem[0].label;
				return category !== "null" ? category : "N.A.";
			}
		}
	},
	plugins: {
		datalabels: {
			formatter: function (_value) {
				return _value > 0 ? parseFloat(_value).toFixed(1) + "%" : "";
			},
		}
	}
};

const BarChartsOptions = {
	scales: {
		xAxes: [
			{
				ticks: {
					callback: function (value) {
						return value ? truncateStr(value, 20) : "N.A.";
					}
				},
				gridLines: {
					display: false
				}
			}
		],
		yAxes: [
			{
				ticks: {
					min: 0,
					callback: function (value) {
						return value + "%";
					}
				},
				scaleLabel: {
					display: true
				}
			}
		]
	},
	responsive: true,
	maintainAspectRatio: false,
	animation: {
		animateRotate: true,
		animateScale: true
	},
	tooltips: {
		backgroundColor: colors.plain.white,
		titleFontColor: colors.plain.primary,
		bodyFontColor: colors.plain.primary,
		borderColor: colors.plain.grey,
		borderWidth: 0.5,
		cornerRadius: 10,
		xPadding: 15,
		yPadding: 10,
		position: 'custom',
		callbacks: {
			label: function (tooltipItem, data) {
				let category = data.datasets[tooltipItem.datasetIndex].label;
				return category + " " + tooltipItem.value + "%";
			},
			title: function (tooltipItem) {
				let category = tooltipItem[0].label;
				return category !== "null" ? category : "N.A.";
			}
		}
	},
	plugins: {
		datalabels: {
			formatter: function (_value) {
				return _value > 0 ? parseFloat(_value).toFixed(1) + "%" : "";
			},
			clamp: true,
			clip: true
		}
	}
};

const PieChartOptions = {
	layout: {
		padding: {
			top: 20,
			bottom: 20
		}
	},
	legend: {
		display: true,
		labels: {
			fontColor: colors.plain.primary,
			fontSize: 16,
			padding: 20
		},
		position: "left"
	},
	tooltips: {
		backgroundColor: colors.plain.white,
		titleFontColor: colors.plain.primary,
		bodyFontColor: colors.plain.primary,
		borderColor: colors.plain.grey,
		borderWidth: 0.5,
		cornerRadius: 10,
		xPadding: 15,
		yPadding: 10,
		callbacks: {
			label: function (tooltipItem, data) {
				let category = data.labels[tooltipItem.index];
				const value = data.datasets[0].data[tooltipItem.index]
				return category ? category + ": " + value : "N.A." + ": " + value;
			}
		}
	},
	responsive: true,
	maintainAspectRatio: false,
	animation: {
		animateRotate: true,
		animateScale: true
	},
	cutoutPercentage: 0,
	plugins: {
		datalabels: {
			formatter: function (_value, context) {
				const data = context.dataset.data;
				let calculatedData = data.slice();
				const legendItems = context.chart.legend.legendItems;

				legendItems.forEach((item, index) => {
					if (item.hidden) {
						calculatedData[index] = 0;
					}
				});

				const total = calculatedData.reduce((a, b) => { return a + b; });
				const percentage = (100 * (_value / total)).toFixed(1);
				return percentage > 0 && !isNaN(percentage) ? percentage + "%" : "";
			},
			display: function(context) {
				const data = context.dataset.data;

				// Hide labels in PieChart when the value to be shown is too little for the slice to contain
				const { datasetIndex, dataIndex } = context;

				let calculatedData = data.slice();

				const total = calculatedData.reduce((a, b) => { return a + b; });
				const percentage = (100 * (calculatedData[dataIndex] / total)).toFixed(1);

				if(datasetIndex) {
					// Adding the inner PieChart, which is way smaller and require a different threshold
					return percentage >= 12;
				} else {
					// Empiric value choosen to display the value plus the "%" character in the basic PieChart
					return percentage >= 6;
				}
			}
		}
	}
};

export { BarChartsOptions, HBarChartsOptions, PieChartOptions };
