<template>
	<div v-if="lastUpdate" class="dashboard-col">
		<span class="update">
			{{ $t('Dashboard.LastUpdate') + ' '}} {{ lastUpdate }}
		</span>

		<span class="update ml-2" v-if="separator">&#8226;</span>
	</div>
</template>

<script>
import CompanyService from "@/services/company.service";

export default {
	data () {
		return {
            companyId: localStorage.getItem("cg-company"),
			lastUpdate: null
		};
	},
	props: {
		separator: Boolean
	},
	async mounted() {
		const company = this.companyId ? await CompanyService.getCompany(this.companyId) : {};
		const date = company.data.last_update;
		this.lastUpdate = date ? this.$moment.utc(date).local().locale(this.$i18n.locale).fromNow() : '';
	}
}
</script>
<style lang="less">
span.update {
	font-weight: 500;
	padding: 2px 0;
	float: left;
	margin: 0;
}
</style>