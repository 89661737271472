function hueToRgb(hue) {
    const saturation = 1; // 0.50;
    const value = 1; // 0.95;

    const c = value * saturation;
    const h = hue / 60;
    const x = c * (1 - Math.abs(h % 2 - 1));

    const map = [[c, x, 0], [x, c, 0], [0, c, x], [0, x, c], [x, 0, c], [c, 0, x]];
    const rgb = map[Math.floor(h)];

    const m = value - c;

    const r = rgb[0] + m;
    const g = rgb[1] + m;
    const b = rgb[2] + m;

    const result = 'rgb(' + 255 * r + ',' + 255 * g + ',' + 255 * b + ')';
    console.log(result);

    return result;
}

function* randomColorGenerator(alpha) {
    const MinHue = 0;
    const MaxHue = 1;

    // 11 ok: 0.09, 0.13
    const Step1 = 0.13;
    const Step2 = 0.07;

    let hueMap = {};
    let last_hue = 0;

    while (true) {
        let hue = last_hue + Step1;

        while (hue < MinHue || hue > MaxHue || hueMap[Math.floor(360 * hue)]) {
            hue += Step2;
            if (hue > 1) hue -= 1;
        }
        const hueIdx = Math.floor(360 * hue);
        let Span = 3
        for (let idx = hueIdx - Span; idx <= hueIdx + Span; idx++) hueMap[idx] = true;
        last_hue = hue;

        const saturation = 0.8;
        const value = 0.85;

        const c = value * saturation;
        const h = hue * 6;
        const x = c * (1 - Math.abs(h % 2 - 1));

        const map = [[c, x, 0], [x, c, 0], [0, c, x], [0, x, c], [x, 0, c], [c, 0, x], [c, 0, x]];
        const rgb = map[Math.floor(h)];

        const m = value - c;

        const r = rgb[0] + m;
        const g = rgb[1] + m;
        const b = rgb[2] + m;

        const result = 'rgba(' + Math.floor(255 * r) + ',' + Math.floor(255 * g) + ',' + Math.floor(255 * b) +  ',' + alpha + ')';

        yield result;
    }
}

export { hueToRgb, randomColorGenerator };